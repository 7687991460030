import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//全局引入vant组件
import Vant from 'vant'
import 'vant/lib/index.css'
import '@/css/global.css'

// 全局引入自定义组件
import PreviewImage from '@/components/PreviewImage.vue' //需要预览的图片用这个组件,传图片数组和src
import BaseNavBar from '@/components/BaseNavBar.vue'
import BottomButton from '@/components/BottomButton.vue' //第二期的底部button组件,如果button内有icon，定义class为icon；如果是表单提交的按钮，加nativeType="submit"
import CommonInfo from '@/components/CommonInfo.vue'
import ReviewPeople from '@/components/review-people/ReviewPeople.vue' //审批流程

import PreviewPicture from '@/components/preview-picture/picture.vue' // 详情内 预览的图片
import ApprovalProcess from '@/components/ApprovalProcess.vue' //  详情内 审批流程

import DetailTitle from '@/components/DetailTitle.vue' // 详情 头部 title

import BottomButtonTwo from '@/components/BottomButtonTwo.vue' // 审批操作按钮

import BottomButtonOne from '@/components/BottomButtonOne.vue' // 审批操作按钮  撤销申请

import { createPinia } from 'pinia'
// 实例化 Pinia

// createApp
const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(Vant)
  .use(createPinia)
  .component('BaseNavBar', BaseNavBar)
  .component('BottomButtonTwo', BottomButtonTwo)
  .component('BottomButtonOne', BottomButtonOne)
  .component('CommonInfo', CommonInfo)
  .component('ReviewPeople', ReviewPeople)
  .component('ApprovalProcess', ApprovalProcess)
  .component('PreviewPicture', PreviewPicture)
  .component('DetailTitle', DetailTitle)
  .component('BottomButton', BottomButton)
  .component('PreviewImage', PreviewImage)
  .mount('#app')
