
import { getApprove } from '@/apis/home'
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  setup(props) {
    const need = ref(null)
    const get_Statistics = async () => {
      const res = await getApprove()
      if (res.code === 200) {
        need.value = res.data.need == 0 ? null : res.data.need
        console.log(need.value)
        localStorage.setItem('NEED', need.value)
      }
    }
    onMounted(() => {
      get_Statistics()
    })
    return {
      need
    }
  }
})
