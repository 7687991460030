import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { reqBase } from './base'

interface projectId extends reqBase {
  project_id?: string | number
}

interface projectlist {
  project: {
    project_name: string
  }
}
export type ProjectData = projectlist

export function getProjectLogList(params: projectId) {
  const url = '/api/project-log/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 项目验收详情
 * GET /api/project_acceptance/detail
 * 接口ID：12308684
 * 接口地址：https://www.apifox.cn/web/project/650314/apis/api-12308684
 */

export function getAcceptanceDetail(params: projectId) {
  const url = '/api/project_acceptance/detail'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 项目各阶段申请详情
 * GET /api/pub/item-detail
 * 接口ID：12754274
 * 接口地址：https://www.apifox.cn/web/project/650314/apis/api-12754274
 */

interface ReqId {
  type: number
  item_id?: number
  project_id?: number
}

export function getItemDetail(params: ReqId) {
  const url = '/api/pub/item-detail'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 费用核算明细
 */

export function getFeeList(params: projectId) {
  const url = '/api/fee/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 费用核算明细
 */

export function getPaymentList(params: projectId) {
  const url = '/api/project_payment/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 资料汇总
 */

export function getMaterialList(params: projectId) {
  const url = '/api/project_material/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 发货计划
 */

export function getDeliveryLogList(params: projectId) {
  const url = 'api/project_delivery_log/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 项目合同
 */

export function getContractList(params: projectId) {
  const url = '/api/contract/detail'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 * 日志列表
 */

interface staff {
  //发起人
  id?: number
  name?: string
  avatar?: string
}
interface projectLog {
  id: number
  project_id: number
  staff_id: number
  content: string
  pics: string
  state: number
  is_reply: number
  created_at: string
  updated_at: string
  deleted_at: string
  staff: staff
  replies: []
}

export type ProjectLog = projectLog

export function getFollowLogList(params: projectId) {
  const url = '/api/project_follow_log/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}
