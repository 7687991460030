import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item, index) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "zoom",
      key: index
    }, [
      item
        ? (_openBlock(), _createBlock(_component_Image, {
            key: 0,
            class: "img",
            width: _ctx.width,
            height: _ctx.height,
            src: item,
            radius: _ctx.radius,
            alt: "预览图片",
            onClick: _withModifiers(($event: any) => (_ctx.getImg(_ctx.listData, index)), ["stop"])
          }, null, 8, ["width", "height", "src", "radius", "onClick"]))
        : _createCommentVNode("", true)
    ])), [
      [_vShow, item]
    ])
  }), 128))
}