import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { reqBase, uploadFileItem } from '@/apis/base'

/**
 * @param params
 * @returns
 * 1、审批列表
 */

interface projectName {
  // 项目
  id?: number
  name?: string
  type_id?: number
  type_name?: string
}
interface staff {
  //发起人
  id?: number
  name?: string
  avatar?: string
}
interface approve_staff {
  //当前处理人
  id?: number
  name?: string
  avatar?: string
}

interface AppRqe {
  approve_staff_id?: number
  created_at?: string
  deleted_at?: string
  grade?: number
  id?: number
  item_id?: number
  staff_id?: number
  state?: number
  state_name?: string
  type?: number
  type_name?: string
  updated_at?: string
  staff?: staff
  approve_staff?: approve_staff
  project?: projectName
}

export type AppRqeList = AppRqe

interface indexReq extends reqBase {
  handle_type?: number // 1待办 2已办 3抄送我 4已发起
}
export function getApproveList(params?: indexReq) {
  const url = '/api/approve/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

export function getCarbonCopyList(params?: indexReq) {
  const url = '/api/carbon-copy/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

interface IdReq {
  id: number
}
interface info {
  approve_staff_id?: number
  grade?: number
  id?: number
  item_id?: number
  project_id?: number
  staff_id?: number
  state?: number
  state_name?: string
  type?: number
  type_name?: string
  project?: projectName
}
export type projectInfo = info

export function getApproveInfo(params: IdReq) {
  const url = '/api/approve/info'
  return cateringRequestHide.get(url, {
    params: params
  })
}

export function getApproveType(params?: IdReq) {
  const url = '/api/pub/approve-type'
  return cateringRequestHide.get(url, {
    params: params
  })
}

interface saveReq {
  id?: number // id
  type?: number //
  item_id?: number
  state?: number // 1同意 2拒绝 3撤销
  suggest?: string // 审批意见
}

/**
 *
 * @param data
 * @returns
 * 审批保存
 * POST /api/approve/save
 * 接口地址：https://www.apifox.cn/web/project/650314/apis/api-12291356
 *
 */

export function ApproveSave(data: saveReq) {
  const url = '/api/approve/save'
  return cateringRequest.post(url, data)
}
