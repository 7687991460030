
import { Image, ImagePreview } from 'vant'
import { reactive, defineComponent, onActivated, onMounted, toRefs, watch } from 'vue'
export default defineComponent({
  components: {
    ['Image']: Image
  },
  props: ['imgs', 'width', 'height', 'radius'],

  // computed: {
  //   listData() {
  //     return this.listData.map((i) => '0_' + i)
  //   },
  // },
  // watch: {
  //   imgs(newV) {
  //     console.log('newV', newV)
  //     this.listData = newV.split(',')
  //   },
  // },

  setup(props) {
    // console.log('图片', props.imgs)
    const state = reactive({
      props,
      listData: []
    })
    watch(
      () => props.imgs,
      (count, prevCount) => {
        state.listData = count.toString().split(',')
        /* ... */
      }
    )
    const getImg = (listData: any, index: number) => {
      ImagePreview({
        images: listData,
        showIndex: true,
        loop: false,
        startPosition: index
      })
    }
    onMounted(() => {
      state.listData = props.imgs.toString().split(',')
    })

    onActivated(() => {})
    return {
      ...toRefs(state),
      getImg
    }
  }
})
