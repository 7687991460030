
import { defineComponent, PropType } from 'vue'
import bannerObj from './ReviewpeopleObj' // 类型

export default defineComponent({
  props: {
    review_carbon: {
      type: Object,
      default: {
        review: [] as PropType<Array<bannerObj>>,
        carbon: [] as PropType<Array<bannerObj>>
      }
    }
  },
  setup(props, { emit }) {
    return {}
  }
})
