import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

//查询省市区
interface cityID {
  pid?: number
}

export function getCity(params: cityID) {
  const url = '/api/area/list'
  return cateringRequestHide.get(url, {
    params
  })
}

// 配置各式类型
interface cityID {
  name?: string // 	配置名：
  col?: string //只取某一项-的配置说明，带个参数。
}

export function getProjectConfigs(params: cityID) {
  const url = '/api/pub/get_project_configs'
  return cateringRequestHide.get(url, {
    params
  })
}
