import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Main from '@/layouts/Main.vue'

// tabbar
import Home from '@/views/home/Index.vue'
import Approval from '@/views/approval/Index.vue'
import Project from '@/views/project/Index.vue'

// 各模块子路由
import approval from './approval'
import project from './project'

// 合并各独立路由
const childRouters = new Set([...approval, ...project])

// 路由声明
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/bind',
    name: 'Bind',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Bind.vue')
  },

  // tabbar下的主界面
  {
    path: '/',
    component: Main,
    children: [
      {
        path: 'home',
        meta: { title: '首页' },
        component: Home
      },
      {
        path: 'approval',
        name: 'Approval',
        meta: { title: '项目审批' },
        component: Approval
      },
      {
        path: 'project',
        name: 'Project',
        meta: { title: '项目列表' },
        component: Project
      }
    ]
  },

  // 各模块子路由，推荐采用懒加载模式载入页面组件
  ...childRouters
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 注册路由守卫
// 后置守卫
router.afterEach((to, from) => {
  let docTitle = '销售管理系统' // 默认标题
  if (to.meta.title) docTitle = to.meta.title as string
  document.title = docTitle

  // 滚动到顶部
  window.scrollTo(0, 0)
})

export default router
