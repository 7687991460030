// import Vrouter from '@/router'

// const router = useRouter();
// const route = useRoute();
// const route = Vrouter.currentRoute.value
// console.log('router---->', route)

import axios, { AxiosInstance, AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Toast } from 'vant'
// import { sdk } from '@/utils/jsSdk'.
import { useStore } from './db'

//获取参数
function getQueryString(name: any) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.href.split('?')[1] ? window.location.href.split('?')[1].match(reg) : null
  if (r != null) return unescape(r[2])
  return null
}

/**
 * 获取错误代码描述
 * @param code code
 * @returns str
 */
function getErrCodeName(code: number) {
  // E_2000(2000, "操作成功"),
  // E_3000(3000, "操作失败"),
  // E_4000(4000, "参数列表错误（缺少，格式不匹配）"),
  // E_4001(4001, "未授权"),
  // E_4003(4003, "访问受限，授权过期"),
  // E_4004(4004, "资源，服务未找到"),
  // E_4005(4005, "登录失败"),
  // E_4006(4006, "密码验证失败"),
  // E_5000(5000, "sa-token鉴权异常"),
  // E_6000(6000, "远程服务器异常"),
  // E_7000(7000, "输入数据验证失败"),

  let name = '未定义'
  switch (code) {
    case 2000:
      name = '操作成功'
      break
    case 3000:
      name = '操作失败'
      break
    case 4000:
      name = '参数列表错误（缺少，格式不匹配）'
      break
    case 4001:
      name = '未授权'
      break
    default:
      name = '请稍后再试'
      break
  }
  return name
}

/**
 * 自定义返回类型
 */
export interface MyRes<T = any> {
  code: number
  msg: string
  data?: T
}
interface MyAxiosInstance extends AxiosInstance {
  request<T = any, R = MyRes<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R>

  get<T = any, R = MyRes<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>

  delete<T = any, R = MyRes<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>

  head<T = any, R = MyRes<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>

  options<T = any, R = MyRes<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>

  post<T = any, R = MyRes<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>

  put<T = any, R = MyRes<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>

  patch<T = any, R = MyRes<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>
}

// 创建axios实例
class MyAxios {
  public baseURL = '' //当前的api网址
  public showLoading = true //是否显示loadding
  public headers = {
    'content-type': 'application/json'
  }

  constructor(baseURL: string, showLoadding: boolean) {
    this.baseURL = baseURL
    this.showLoading = showLoadding
  }

  // 设置baseURL
  setBaseURL(url: string): MyAxios {
    this.baseURL = url
    return this // 链式调用
  }

  //是否展示loadding框
  setShowLoadding(show: boolean): MyAxios {
    this.showLoading = show
    return this
  }

  setHeaders(headers: any): MyAxios {
    this.headers = headers
    return this
  }

  // 创建axios实例
  // 最终调用
  create(): MyAxiosInstance {
    const baseURL = this.baseURL
    const _instance = axios.create({
      baseURL,
      headers: this.headers,
      timeout: 1000 * 60, // 请求超时时间
      // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject promise
      // 如果 `validateStatus` 返回`true`(或者设置为`null` 或`undefined`)，promise 将被 resolve; 否则，promise 将被 rejecte
      validateStatus: function (status) {
        return status >= 200 && status < 600 // default
      }
    })
    // 是否正在刷新的标记
    const isRefreshing = false

    // request拦截
    _instance.interceptors.request.use(
      async (config: any) => {
        const store = useStore()
        // 请求前配置
        if (this.showLoading) {
          // loadding框
          Toast.loading({
            message: '请稍等...',
            forbidClick: true
          })
        }

        // add code
        // let token = getQueryString('zgtoken-app')
        let token: any = ''
        let token_name: any = 'Authorization'
        try {
          //异步
          const res: any = await store.getToken
          // console.log('======>  fetch', res)
          token = res
        } catch (error) {
          //  token =
          //    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3QiLCJhdWQiOiJodHRwOlwvXC9sb2NhbGhvc3QiLCJqdGkiOiJqd3Q2MjhhZTJiZGI5OWQwIiwiaWF0IjoxNjUzMjY5MTgxLCJleHAiOjE2NTU4NjExODEsInVzZXJfaWQiOjg3LCJ1c2VyX25hbWUiOiJcdTVkMTRcdTRmNTlcdTVlMDUiLCJ0b2tlbl9mbGFnIjp0cnVlfQ.QoAEF4tGNhShMDezrTgs837BzHit5GFAb2dpnMc_wJ0'
          // token = 'd318fcee017749e3929b029837e67313' //李玉涛-电脑
          token_name = 'Authorization'
        }
        if (token) {
          token_name = 'Authorization'
          config.headers[token_name] = 'Bearer' + ' ' + token
        }
        return config
      },
      error => {
        // 错误处理
        // add code
        return Promise.reject(error)
      }
    )

    // response拦截
    _instance.interceptors.response.use(
      async (response: any) => {
        // 响应数据处理
        if (this.showLoading) {
          // 清空loadding
          Toast.clear()
        }

        //再次请求接口
        if (response.status == 401) {
          return new Promise(resolve => {
            setTimeout(() => {
              _instance.request(response.config)
              // console.log(router)
              // Vrouter.push('/login')
            }, 2000)
          })
        }

        if (response.status !== 200) {
          console.log('如果接口状态不是200======>', response)
          const msg = 'code:' + response.status + '\n' + response.statusText
          Toast(msg)
          return response
        }

        // if (response.data.code != 2000) {
        //   console.log('如果接口code不是2000========>', response)
        //   Toast.fail(response.data.msg)
        // }

        // add code
        return response.data
      },
      error => {
        console.log('打印错误----------->')
        console.dir(error)
        // 错误处理
        // add code
        Toast.fail('网络异常')
        return Promise.reject(error)
      }
    )

    return _instance
  }
}

//小餐饮App
const webUrl = 'https://oa-sales-api.hbzgyc.com'
// const webUrl = 'http://192.168.1.34:7001'

const cateringRequest = new MyAxios(webUrl, true).create()
const cateringRequestHide = new MyAxios(webUrl, false).create()

// 不显示loading效果进行请求
const cateringUploadsHide = new MyAxios(webUrl, false).setHeaders({ 'Content-Type': 'multipart/form-data' }).create()

export { cateringRequest, cateringRequestHide, cateringUploadsHide }
