import { defineStore } from 'pinia'
export const useStore = defineStore('main', {
  // other options...
  state: () => {
    return {
      // 所有这些属性都将自动推断其数据类型
      // items: [],
      // counter: 0,
      // name: 'Eduardo',
      // isAdmin: true,
      userinfo: {
        staff_id: 82,
        name: '李根',
        phone: '15271126823',
        wx_user: {
          city: '',
          country: '',
          headimgurl:
            'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLIzFLPBPOPpbCjNOdfIvVsz11aKXhuWoXvFPYxuVMGfuOT8wYDjH1FDJPRC63J3Seiccc0XuukicvQ/132',
          language: '',
          nickname: '道法自然',
          openid: 'ooUV159RhvRetvuQZtQ_AKCE-bTw',
          province: '',
          sex: 0,
          unionid: 'o1GaTwn6RzWbxf6B_JUtbkQEKc4A'
        }
      },
      token: ''
    }
  },
  getters: {
    getUserInfo: state => state.userinfo,
    getToken: state => state.token
    //doubleCount(state){
    //  console.log(this,'想要在getter中获取该store的其他内容则不能用箭头函数，')
    //  return state.counter * 2
    //},
  },
  actions: {
    setToken(token) {
      this.token = token
    },
    setUserInfo(userinfo) {
      this.userinfo = userinfo
    }
    // increment(num, test1, test2) {
    //   console.log(num, test1, test2)
    //   this.counter++
    // },
    // randomizeCounter() {
    //   this.counter = Math.round(100 * Math.random())
    // }
  }
})
