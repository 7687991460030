// 审批模块路由
import Container from '@/layouts/Container.vue'
import AuditDeclare from '@/views/AuditDeclare.vue'

export default [
  {
    path: '/project',
    component: Container,
    children: [
      {
        path: 'auditDeclare',
        meta: { title: '项目申报' },
        component: () => import('@/views/AuditDeclare.vue')
      },
      {
        path: 'expense-declaration/add',
        name: 'ExpenseDeclarationAdd',
        meta: { title: '费用申报' },
        component: () => import('@/views/project/ExpenseDeclaration/Add.vue')
      },
      {
        path: 'expense-declaration/detail',
        name: 'ExpenseDeclarationDetail',
        meta: { title: '费用申报详情' },
        component: () => import('@/views/project/ExpenseDeclaration/Detail.vue')
      },
      {
        path: 'progress-report/add',
        name: 'ProgressReportAdd',
        meta: { title: '进度汇报' },
        component: () => import('@/views/project/ProgressReport/Add.vue')
      },
      {
        path: 'progress-report/addLog',
        name: 'ProgressReportAddLog',
        meta: { title: '跟进日志' },
        component: () => import('@/views/project/ProgressReport/AddLog.vue')
      },
      {
        path: 'progress-report/detail',
        name: 'ProgressReportDetail',
        meta: { title: '进度汇报详情' },
        component: () => import('@/views/project/ProgressReport/Detail.vue')
      },
      {
        path: 'visit-record/add',
        name: 'VisitRecordAdd',
        meta: { title: '拜访记录' },
        component: () => import('@/views/project/VisitRecord/Add.vue')
      },
      {
        path: 'visit-record/detail',
        name: 'VisitRecordDetail',
        meta: { title: '拜访记录详情' },
        component: () => import('@/views/project/VisitRecord/Detail.vue')
      },
      {
        path: 'project-termination/add',
        name: 'ProjectTerminationAdd',
        meta: { title: '项目终止' },
        component: () => import('@/views/project/ProjectTermination/Add.vue')
      },
      {
        path: 'project-termination/detail',
        name: 'ProjectTerminationDetail',
        meta: { title: '项目终止详情' },
        component: () => import('@/views/project/ProjectTermination/Detail.vue')
      },
      {
        path: 'payment-collection/add',
        name: 'PaymentCollectionAdd',
        meta: { title: '项目回款' },
        component: () => import('@/views/project/PaymentCollection/Add.vue')
      },
      {
        path: 'payment-collection/detail',
        name: 'PaymentCollectionDetail',
        meta: { title: '项目回款详情' },
        component: () => import('@/views/project/PaymentCollection/Detail.vue')
      },
      {
        path: 'projectDetail',
        name: 'ProjectDetail',
        meta: { title: '项目详情', keepAlive: true },
        component: () => import('@/views/project/ProjectDetail/ProjectDetail.vue')
      },
      {
        path: 'projectFollowLog',
        name: 'ProjectFollowLog',
        meta: { title: '项目跟进日志' },
        component: () => import('@/views/project/ProjectDetail/ProjectFollowLog.vue')
      },
      {
        path: 'fileCollect',
        name: 'FileCollect',
        meta: { title: '资料汇总' },
        component: () => import('@/views/project/ProjectDetail/FileCollect.vue')
      },
      {
        path: 'deliverPlan',
        name: 'DeliverPlan',
        meta: { title: '发货计划' },
        component: () => import('@/views/project/ProjectDetail/DeliverPlan.vue')
      },
      {
        path: 'contract',
        name: 'Contract',
        meta: { title: '合同明细' },
        component: () => import('@/views/project/ProjectDetail/Contract.vue')
      },
      {
        path: 'costAccounting',
        name: 'CostAccounting',
        meta: { title: '费用核算' },
        component: () => import('@/views/project/ProjectDetail/CostAccounting.vue')
      },
      {
        path: 'costAccountingDetail',
        name: 'CostAccountingDetail',
        meta: { title: '费用核算明细' },
        component: () => import('@/views/project/ProjectDetail/CostAccountingDetail.vue')
      },
      //项目验收
      {
        path: 'acceptance-add',
        name: 'AcceptanceAdd',
        meta: { title: '项目验收' },
        component: () => import('@/views/project/ProjectAcceptance/AcceptanceAdd.vue')
      },
      {
        path: 'acceptance-info',
        name: 'AcceptanceInfo',
        meta: { title: '项目验收详情' },
        component: () => import('@/views/project/ProjectAcceptance/AcceptanceInfo.vue')
      },
      //项目完结申请
      {
        path: 'completeapply-add',
        name: 'CompleteApplyAdd',
        meta: { title: '项目完结申请' },
        component: () => import('@/views/project/ProjectComplete/CompleteApply.vue')
      },
      {
        path: 'completeapply-info',
        name: 'CompleteApplyInfo',
        meta: { title: '完结申请详情' },
        component: () => import('@/views/project/ProjectComplete/CompleteInfo.vue')
      },
      //施工申请
      {
        path: 'construction-add',
        name: 'ConstructionAdd',
        meta: { title: '施工申请' },
        component: () => import('@/views/project/ProjectConstruction/ConstructionAdd.vue')
      },
      {
        path: 'construction-info',
        name: 'ConstructionInfo',
        meta: { title: '施工申请详情' },
        component: () => import('@/views/project/ProjectConstruction/ConstructionInfo.vue')
      },
      {
        path: 'construction-add-one',
        name: 'ConstructionAddOne',
        meta: { title: '施工申请' },
        component: () => import('@/views/project/ProjectConstruction/ConstructionAddOne.vue')
      },
      // 项目申报
      {
        path: 'declare',
        name: 'Declare',
        meta: { title: '项目申报' },
        component: () => import('@/views/project/ProjectDeclare/Index.vue')
      },
      // 项目申报 详情
      {
        path: 'declare-info',
        name: 'DeclareInfo',
        meta: { title: '项目申报详情' },
        component: () => import('@/views/project/ProjectDeclare/Info.vue')
      },
      //施工进度
      {
        path: 'schedule-add',
        name: 'ScheduleAdd',
        meta: { title: '施工进度' },
        component: () => import('@/views/project/ConstructionSchedule/ScheduleAdd.vue')
      },
      {
        path: 'schedule-info',
        name: 'ScheduleInfo',
        meta: { title: '施工进度详情' },
        component: () => import('@/views/project/ConstructionSchedule/ScheduleInfo.vue')
      },
      //发货申请
      {
        path: 'sendapply-add',
        name: 'SendapplyAdd',
        meta: { title: '发货申请' },
        component: () => import('@/views/project/ProjectSendApply/SendApplyAdd.vue')
      },
      {
        path: 'sendapply-info',
        name: 'SendapplyInfo',
        meta: { title: '发货申请详情' },
        component: () => import('@/views/project/ProjectSendApply/SendApplyInfo.vue')
      },
      {
        path: 'replenish-add',
        name: 'ReplenishAdd',
        meta: { title: '补充协议' },
        component: () => import('@/views/project/ReplenishPact/ReplenishAdd.vue')
      },
      {
        path: 'replenish-info',
        name: 'ReplenishInfo',
        meta: { title: '补充协议详情' },
        component: () => import('@/views/project/ReplenishPact/ReplenishInfo.vue')
      },
      {
        path: 'uploadcontract-add',
        name: 'UploadcontractAdd',
        meta: { title: '合同上传' },
        component: () => import('@/views/project/UploadContract/UploadContractAdd.vue')
      },
      {
        path: 'uploadcontract-info',
        name: 'UploadcontractInfo',
        meta: { title: '合同上传详情' },
        component: () => import('@/views/project/UploadContract/UploadContractInfo.vue')
      },
      {
        path: 'datasummary-add',
        name: 'DatasummaryAdd',
        meta: { title: '资料汇总' },
        component: () => import('@/views/project/DataSummary/SummaryAdd.vue')
      }
    ]
  }
]
