import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { reqBase, reqCity } from '@/apis/base'
/**
 *
 * 项目列表接口
 *staff_id: string // 登录人
 *stage?: string // 0全部，1:跟进阶段，2：施工阶段，3：收尾阶段 4：项目已完结
 *status?: string //0：未完结，1：已完结，2：已终止
 */
interface projectList extends reqBase {
  staff_id?: string | number // 登录人
  type?: string | number //  0:全部，1：我负责的，2：我参与的
  stage?: string | number // 0全部，1:跟进阶段，2：施工阶段，3：收尾阶段 4：项目已完结
  status?: string | number //0：未完结，1：已完结，2：已终止
  leader_id?: string | number
  limit?: string | number // 展示几条数据
}

export function getProjectList(params: projectList) {
  const url = '/api/project/list'
  return cateringRequestHide.get(url, {
    params: params
  })
}

//查询项目 详情
interface projectId {
  id?: string | number
}

interface projectlist extends reqCity {
  id: number // 项目id
  name: string //项目名称
  number: string // 项目编号
  stage?: number
  stage_name: string // 1:跟进阶段，2：施工阶段，3：收尾阶段 4：项目已完结
  leader: {
    avatar: string
    id?: number
    job_name: string
    jobid: number
    name: string
  } // 负责人详细信息
  type_id_descs: string // 类别：
  contract_number: string // 合同编号
  inspection_instructions: string // 说明
  capital_source: string // 资金来源
  project_size: string // 规模
  project_type: string // 工程类型
  mode_operation: string // 经营方式
  contact_name: string // 联系人
  contact_phone: string // 联系人电话
  declare_type_name: string // 申报类型
  collaboration_staff_name: string // 协作人
  introduce_staff_name: string // 介绍人
  application_unit: string // 申报单位
  created_at: string // 申报时间
  staff_name: string // 发起人
  acceptance_time: string // 验收时间

  tender_organ_name: string // 招标机构
  tender_name: string // 招标方式

  quality_assurance_term: string // 质保期限

  // 项目资金概况
  receivable_money?: string // 回款金额
  contract_money?: string // 合同总金额
  quality_assurance_money?: string // 质保金
  forecast_money?: string // 预估利润
  // 支出及成本
  person_fee?: string // 支出及成本：个人业务费用（元）
  goods_money?: string // 支出及成本：货物成本
  installation_money?: string // 支出及成本：安装成本
  planning_money?: string // 支出及成本：策划设计费
  tender_money?: string // 支出及成本：招标费用
  belong_area?: string // 所属地
  belong_area_name?: string // 所属地
  // 项目提成
  ticheng_reward_money?: string // 提成：预计奖励金额
  ticheng_honour_money?: string // 提成：兑付的金额，就是已完成发放的金额
  ticheng_tidian?: string //  提成：提点
  person_type_desc?: string
  type_name?: string
  leader_id?: number //负责人ID
  contract?: {
    number: string
    shelf_life: string
    money: string
    shelf_life_money: string
  }
  design_staff?: {
    name?: string
  } // 设计总师

  engrg_staff?: {
    name?: string
  } // 工程总师

  canyu_staffs_names?: string // 参与人员

  state?: number // 0审核中 1审核通过 2未通过 3项目完成 4项目终止
}
export type ProjectData = projectlist

export function getProjectDetail(params: projectId) {
  const url = '/api/project/detail'
  return cateringRequest.get(url, {
    params
  })
}
