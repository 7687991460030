import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['nav-bar', _ctx.blue ? 'blue' : '']),
    style: _normalizeStyle({ paddingTop: _ctx.state.statusBarHeight + 'px' })
  }, [
    _createVNode(_component_van_nav_bar, {
      style: _normalizeStyle({ paddingTop: _ctx.state.statusBarHeight + 'px' }),
      title: _ctx.title,
      border: _ctx.border,
      "left-arrow": "",
      onClickLeft: _ctx.onClickLeft,
      "right-text": _ctx.rightText,
      onClickRight: _ctx.onClickRight,
      "safe-area-inset-top": "",
      fixed: "",
      "z-index": "10000"
    }, null, 8, ["style", "title", "border", "onClickLeft", "right-text", "onClickRight"])
  ], 6))
}