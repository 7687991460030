// 审批模块路由
import Container from '@/layouts/Container.vue'

// 页面
const Approval = () => import('@/views/approval/Index.vue')
const ApprovalInfo = () => import('@/views/approval/Info.vue')
const Operate = () => import('@/views/approval/Operate.vue')

export default [
  {
    path: '/approval',
    component: Container,
    children: [
      {
        path: 'info',
        name: 'ApprovalInfo',
        meta: { title: '审批详情' },
        component: ApprovalInfo
      },
      {
        path: 'operate',
        name: 'Operate',
        meta: { title: '审批意见' },
        component: Operate
      }
    ]
  }
]
