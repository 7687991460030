import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4181ccfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    })
  ]))
}