import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

interface projectList {
  staff_id?: string | number // 登录人
}

export function getProjectStatistics(params?: projectList) {
  const url = '/api/project/statistics'
  return cateringRequestHide.get(url, {
    params: params
  })
}

/**
 *
 * @param params
 * @returns
 *
 */
export function getApprove(params?: projectList) {
  const url = '/api/approve/statistics'
  return cateringRequestHide.get(url, {
    params: params
  })
}

// 个人信息查询
export function getStaffInfo() {
  const url = '/api/staff/info'
  return cateringRequestHide.get(url)
}
