
import { defineComponent, onActivated, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
// import { sdk } from './../utils/jsSdk'

export default defineComponent({
  props: {
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    border: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    rightText: {
      type: String
    },

    // 是否为蓝色的nav-bar
    blue: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },

  setup(props, { emit }) {
    const state = reactive({
      statusBarHeight: 0
    })
    const router = useRouter()

    //获取状态栏的高度
    const get_bar = async () => {
      // try {
      //   let res: any = await sdk.getBarHeight()
      //   state.statusBarHeight = res.barHeight
      // } catch (e) {
      //   state.statusBarHeight = 0
      // }
    }

    //点击左侧返回
    const onClickLeft = async () => {
      router.back()
    }

    const onClickRight = () => {
      // Toast.success('按钮')
      emit('onClickRight')
    }

    // 在 <script setup> 中必须使用 defineProps 和 defineEmits API 来声明 props 和 emits
    // const props = defineProps({
    //   title: String,
    // })
    onActivated(() => {
      get_bar()
    })
    return {
      state,
      onClickLeft,
      onClickRight
    }
  }
})
